import { enableBodyScroll, disableBodyScroll } from "./body-scroll-lock"

/**
 * @property {HTMLElement} element
 * @property {string[]} gallery Chemins des images
 * @property {string} url Image affichée
 */
class Lightbox {
    static init () {
        const links = Array.from(document.querySelectorAll('a[href$=".jpg"], a[href$=".jpeg"], a[href$=".png"]'))
        const gallery = links.map(link => link.getAttribute('href'))

        links.forEach(link => link.addEventListener('click', e => {
            e.preventDefault()
            new Lightbox(e.currentTarget.getAttribute('href'), gallery)
        }))
    }

    /**
     * 
     * @param {string} url Url de l'image 
     * @param {string[]} gallery Chemin des images
     */
    constructor (url, gallery){
        this.element = this.buildDOM(url);
        this.gallery = gallery
        this.loadImage(url);
        this.onKeyUp = this.onKeyUp.bind(this)
        document.body.appendChild(this.element);
        disableBodyScroll(this.element)
        document.addEventListener('keyup', this.onKeyUp)
    }

    /**
     * 
     * @param {string} url Url de l'image 
     */
    loadImage (url) {
        this.url = null
        const image = new Image();
        const container = this.element.querySelector('.lightbox_container')
        const zoom = this.element.querySelector('.lightbox_zoom')
        const loader = document.createElement('div')
        loader.classList.add('lightbox_loader')
        loader.innerHTML = `
                    <div class="loader">
                        <span style="--i:1;"></span>
                        <span style="--i:2;"></span>
                        <span style="--i:3;"></span>
                        <span style="--i:4;"></span>
                        <span style="--i:5"></span>
                        <span style="--i:6;"></span>
                        <span style="--i:7;"></span>
                    </div>
                    <svg>
                        <filter id="gooey">
                            <fegaussianblur in="sourcegraphic" stdDeviation="10"/>
                            <fecolormatrix values="
                                        1 0 0 0 0
                                        1 1 0 0 0
                                        1 0 1 0 0
                                        1 0 0 20 -10
                            "/>
                        </filter>
                    </svg>
        `

        this.onLoading(url, zoom, image, loader)
        image.src = url
        image.classList.add('lightbox_img')
        container.addEventListener('click', this.close.bind(this))
        const firstUrl = image.src
        var imgName = image.src.substring(image.src.lastIndexOf("/")+1, image.src.length)
        window.setTimeout(() => {
            zoom.addEventListener('click',  (e) => {
                e.preventDefault()
                var prefixImgName = imgName.substring(0, imgName.indexOf("-"))

                if (prefixImgName != "full") {
                    imgName = "full-" + imgName
                    this.onLoading(url, zoom, image, loader)
                    image.src = "../images/" + imgName
                }

                if (prefixImgName == "full") {
                    this.onLoading(url, zoom, image, loader)
                    image.src = firstUrl
                    imgName = image.src.substring(image.src.lastIndexOf("/")+1, image.src.length)
                }

                e.stopPropagation()
            })
        }, 500)
    }


    /** 
     * @param {string} url
     * @param {Element} zoom
     * @param {HTMLImageElement} image
     * @param {HTMLDivElement} loader
    */
    onLoading (url, zoom, image, loader) {
        zoom.innerHTML = ''
        zoom.appendChild(loader)
        image.onload = () => {
            zoom.removeChild(loader)
            zoom.appendChild(image)
            this.url = url
        }
    }

    /**
     * 
     * @param {KeyboardEvent} e 
     */
    onKeyUp (e) {
        if (e.key === 'Escape'){
            this.close(e)
        } else if (e.key === 'ArrowLeft'){
            this.prev(e)
        } else if (e.key === 'ArrowRight'){
            this.next(e)
        }
    }

    /**
     * Ferme la lightbox
     * @param {MouseEvent|KeyboardEvent} e 
     */
    close (e){
        e.preventDefault()
        this.element.classList.add('fadeOut')
        enableBodyScroll(this.element)
        window.setTimeout(() => {
            this.element.parentElement.removeChild(this.element)
        }, 500)
        document.removeEventListener('keyup', this.onKeyUp)
    }

    /**
     * @param {MouseEvent|KeyboardEvent} e 
     */
    next (e) {
        e.preventDefault
        let i = this.gallery.findIndex(image => image === this.url)
        if (i === this.gallery.length - 1) {
            i = -1
        }
        this.loadImage(this.gallery[i + 1])
    }

     /**
     * @param {MouseEvent|KeyboardEvent} e 
     */
     prev (e) {
        e.preventDefault
        let i = this.gallery.findIndex(image => image === this.url)
        if (i === 0) {
            i = this.gallery.length
        }
        this.loadImage(this.gallery[i - 1])
    }

    /**
     * 
     * @param {string} url Url de l'image
     * @return {HTMLElement}
     */
    buildDOM (url){
        const dom = document.createElement('div');
        dom.classList.add('lightbox');
        dom.innerHTML = `
            <button class="lightbox_close">Fermer</button>
            <button class="lightbox_prev">Précédent
                <div class="arrow">
                    <div class="line"></div>
                    <div class="line line2"></div>
                </div>
            </button>
            <button class="lightbox_next">Suivant
                <div class="arrow">
                    <div class="line"></div>
                    <div class="line line2"></div>
                </div>
            </button>
            <div class="lightbox_container">
                <div class="lightbox_zoom"></div>
            </div>
        `
        dom.querySelector('.lightbox_close').addEventListener('click', this.close.bind(this))
        dom.querySelector('.lightbox_next').addEventListener('click', this.next.bind(this))
        dom.querySelector('.lightbox_prev').addEventListener('click', this.prev.bind(this))
        return dom;
    }
}


Lightbox.init()